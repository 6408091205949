import React from "react";
import { Link } from 'react-router-dom';
import './Navbar.css';
import { useLocation } from 'react-router-dom';

function AccordionMenuWrapper(props) {
  const location = useLocation();
  return <AccordionMenu {...props} location={location} />;
}

class AccordionMenu extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        contentVisible: false,
        initialScroll: null // to store the starting scroll position
      };

      // bind event handler methods here (defined below) for callback to work
      this.toggleContentVisible = this.toggleContentVisible.bind(this);
    }
  
    toggleContentVisible(event) {
      // called at onClick of accordion button
      // toggles contentVisible between true and false
      // this.setState((prevState) => {
      //   return { contentVisible: !prevState.contentVisible };
      // });

      // Check if the event source is a Link (optional but good for safety)
    if(event && event.target.tagName === "A") {
      this.setState({ contentVisible: false });
  } else {
      this.setState((prevState) => {
          return { contentVisible: !prevState.contentVisible };
      });
  }
    }

    navbarMenuRef = React.createRef();

    componentDidMount() {
      // Add an event listener to the document object
      document.addEventListener("click", this.handleClick);
      window.addEventListener("scroll", this.handleScroll);
    }
  
    componentWillUnmount() {
      // Remove the event listener from the document object
      document.removeEventListener("click", this.handleClick);
      window.removeEventListener('scroll', this.handleScroll);
    }
  
    handleClick = event => {
      // Get the element that the ref is attached to
      const element = this.navbarMenuRef.current;
  
      // Check if the click occurred inside or outside the element
      if (element && !element.contains(event.target)) {
        // The click occurred outside the element
        // Call the toggleContentVisible function to toggle the content visibility
        this.setState({ contentVisible: false });
      } else {
        // The click occurred inside the element
        // Add your code here to handle the click inside event
      }
    };

    handleScroll = () => {
      const isDesktop = window.innerWidth > 769;
      const scrollPosition = window.pageYOffset || document.documentElement.scrollTop;

      if (!isDesktop) {
        return;
      }
      
       // If content is visible and initial scroll is not set, set it
      if (this.state.contentVisible && this.state.initialScroll === null) {
        this.setState({ initialScroll: scrollPosition });
      }

      const scrollDifference = Math.abs(scrollPosition - (this.state.initialScroll || 0));
      const SCROLL_THRESHOLD = 5; // amount in pixels

      const atBottomOfPage = scrollPosition >= document.body.offsetHeight - window.innerHeight;
      const onAboutPage = this.props.location && this.props.location.pathname === "/";

      if (scrollPosition === 0) {
          this.setState({ contentVisible: true });
      } else if (scrollDifference >= SCROLL_THRESHOLD || (onAboutPage && atBottomOfPage)) {
          this.setState({ contentVisible: false, initialScroll: null });
      }
    };
  
    render() {
      return (
        

        <div className={`accordion_container ${
          this.state.contentVisible ? "container_expanded" : ""
        } ${
          this.props.location.pathname === "/" ? "homepage" : ""}`} 
          ref={this.navbarMenuRef}>

          {/* Pass the ref as a prop to the Footer Component*/}
          {/* <Footer ref={this.navbarMenuRef} />  */}

          <div className="accordion">
            {/* when button clicked, toggle the contentVisible value in our state*/}
            <button
              type="button"
              className="accordion_title"
              onClick={this.toggleContentVisible}
            >
              <i className={`fas fa-plus ${this.state.contentVisible ? 'rotateNav' : ''}`}></i>
  
            </button>
          </div>
          {/* For the below Div hide / show:
              Using string literals, and a ternary if check on our states contentVisible value,
              we append the .show_content CSS class if contentVisible === true
              else we append the empty string if contentVisible === false
              Note: We are just combining multiple CSS classes here to create different effects
              Same as how we use CSS class combination in regular web pages to create visuals.
          */}
          <div
            className={`accordion_content ${
              this.state.contentVisible ? "show_content" : ""
            }`}
          >
            <div className="list_item_container aboutLink" onClick={this.toggleContentVisible}>
              <Link to={{ 
                pathname: "/", 
                state: { showContentImmediately: true }
                }}  
                >Home<i class="fas fa-tv" style={{ fontSize: 20, padding: 10}}></i></Link>
            </div>
            <div className="list_item_container creditsLink" onClick={this.toggleContentVisible}>
              <Link to="/credits" ><i class="fas fa-music" style={{ fontSize: 20, padding: 10}}></i>Credits</Link>
            </div>
            <div className="list_item_container aboutMeLink" onClick={this.toggleContentVisible}>
              <Link to="/aboutme" ><i class="fas fa-user" style={{ fontSize: 20, padding: 10}}></i>About Me</Link>
            </div>
            {/* <div className="list_item_container appsLink">
              <Link to="/apps" >Apps</Link>
            </div> */}
            <div className="list_item_container contactLink" onClick={this.toggleContentVisible}>
              <Link to="/contact" >Contact<i class="fas fa-at" style={{ fontSize: 20, padding: 10}}></i></Link>
            </div>
          </div>
          
        </div>


  

      );
    }
  }

export default AccordionMenuWrapper;