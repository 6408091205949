import React, { useState, useEffect, useRef } from 'react';
import './Footer.css';
import './Navbar.css'

const Footer = (props) => {

    const [isFooterOpen, setFooterOpen] = useState(false);
    const [hasScrolled, setHasScrolled] = useState(false); // Track if initial scroll has occurred
    const footerRef = useRef(null);

    useEffect(() => {
        const handleScroll = () => {
            // Close the footer whenever there is a scroll
            setFooterOpen(false);
            
            if (!hasScrolled) {
                setHasScrolled(true); // Mark that initial scroll has occurred
            }

            // Open the footer when scrolled to the bottom after the initial scroll
            if (hasScrolled && window.innerHeight + window.scrollY >= document.documentElement.scrollHeight) {
                setFooterOpen(true);
            }
        };

        const handleClickOutside = (event) => {
            if (footerRef.current && !footerRef.current.contains(event.target)) {
              // Clicked outside the footer, close it
              setFooterOpen(false);
            }
          };

        window.addEventListener('scroll', handleScroll);
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            window.removeEventListener('scroll', handleScroll);
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [hasScrolled]);

    

    const toggleFooter = () => {
        setFooterOpen(prevState => !prevState);
    };
    

    return (
        <div className='footer-wrapper'>
            <footer className={`footer ${isFooterOpen ? 'open' : ''}`} ref={footerRef}>
                
                <div className="menu-toggle" onClick={toggleFooter}> {/* Add icon here */}
                <i class="fas fa-chevron-right" id="play-icon"></i>
                </div>
                {/* < AccordionMenu />
                <div className='spacerDiv'></div> */} 
                {/* Hi  */}

                    <a href="https://soundbetter.com/profiles/265735-dalton-allison" className="social-icon"><i className="fas fa-headphones-alt"></i></a>

                    <a href="https://music.apple.com/us/artist/daallher/1435144773" className="social-icon"><i className="fab fa-itunes"></i></a>

                    <a href="https://www.youtube.com/@daallher" className="social-icon"><i className="fab fa-youtube"></i></a>

                    <a href="https://daallher.bandcamp.com/" className="social-icon"><i className="fab fa-bandcamp"></i></a>

                    <a href="https://www.instagram.com/daallher/" className="social-icon"><i className="fab fa-instagram"></i></a>

                    <a href="https://open.spotify.com/artist/0QJf8vIETmuwZB6KRDNiuz?si=rsmj64d0RU-E0lSiDcgong" className="social-icon"><i className="fab fa-spotify"></i></a>

                    <i></i>

            </footer>
        </ div>
    );
}

export default Footer;