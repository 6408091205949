import React, { Suspense, useEffect } from 'react';
import AccordionMenu from './Components/Navbar';
import Footer from './Components/Footer';
import './App.css';
import { Routes, Route } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

// Lazy load components
const About = React.lazy(() => import('./Components/About/About'));
const AboutMe = React.lazy(() => import('./Components/AboutMe/AboutMe'));
const Portfolio = React.lazy(() => import('./Components/Portfolio/Portfolio'));
const Apps = React.lazy(() => import('./Components/Apps/Apps'));
const ThisSite = React.lazy(() => import('./Components/ThisSite/ThisSite'));
const Contact = React.lazy(() => import('./Components/Contact/Contact'));
const ThankYou = React.lazy(() => import('./Components/Contact/thank-you'));

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

function App() {
  return (
    <>
      <AccordionMenu />

      <Footer />

      <ScrollToTop />

      <Suspense fallback={<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}></div>}>
        <Routes>
          <Route path="/" element={<About />} />
          <Route path="/aboutme" element={<AboutMe />} />
          <Route path="/credits" element={<Portfolio />} />
          <Route path="/apps" element={<Apps />} />
          <Route path="/this-site" element={<ThisSite />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/thank-you" element={<ThankYou />} />
        </Routes>
      </Suspense>
    </>
  );
}

export default App;
